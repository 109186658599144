import { Avatar, Form, Radio } from "antd";
import React from "react";

type IProps = {
  name: string;
  label: string;
  sizes: any[];
};

const SizesSelector = (props: IProps) => {
  const { name, label, sizes } = props;

  return (
    <div className="sizes-selector">
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: "Por favor selecciona el tamaño",
          },
        ]}
        hasFeedback={false}
        colon={true}
      >
        <Radio.Group className="sizes-selector-wrapper">
          {sizes.map((size: any, index: number) => (
            <Radio.Button
              key={`${index}`}
              value={size.id}
              className="sizes-selector-button"
            >
              <div className="sizes-selector-body">
                <div className="sizes-selector-ribbon">{size.name}</div>
                <div className="gx-d-flex" style={{ flexFlow: "row wrap" }}>
                  <div className="sizes-selector-image">
                    <Avatar
                      src={require(`assets/images/packages/${size.name.toLowerCase()}.png`)}
                      shape="square"
                      size={{
                        xs: 80,
                        sm: 80,
                        md: 80,
                        lg: 120,
                        xl: 120,
                        xxl: 120,
                      }}
                      className="gx-my-4"
                    />
                  </div>
                  <div className="gx-px-2 gx-text-left gx-bg-primary gx-text-white">
                    Medidas menores a: {size.description}
                    <br />
                    Peso máximo: {size.weigth}kg
                  </div>
                </div>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default SizesSelector;
