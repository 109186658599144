export const getError = (
  error: any,
  defaultMessage: string = "Error al procesar la solicitud"
) => {
  let message: string | string[] = "";

  if (error?.response) {
    message = error?.response?.data?.error
      ? error.response.data.error
      : defaultMessage;
  } else {
    message = error?.message ? error.message : defaultMessage;
  }

  if (message instanceof Object) {
    let messages = [];
    for (const item in message) {
      if (message.hasOwnProperty(item)) {
        messages.push(message[item]);
      }
    }
    message = messages;
  }

  return message;
};
