import { push } from "connected-react-router";

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  LOGOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from "../../constants/ActionTypes";
import { API, getError } from "../../util";

type DispatchType = ({
  type,
  payload,
}: {
  type: string | null;
  payload?: any;
}) => void;

/**
 * Ponemos la URL inicial
 */
export const setInitUrl = (url: string) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

/**
 * Inicio de sesión
 */
export const userLogin = ({
  user,
  password,
}: {
  user: string;
  password: string;
}) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("admin/auth/login", { user, password });
      if (!response?.data?.token) {
        throw new Error("Error al iniciar sesión.");
      }
      localStorage.setItem("token", JSON.stringify(response.data.token));
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_TOKEN_SET, payload: response.data.token });
      dispatch({ type: USER_DATA, payload: response.data.data });
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

/**
 * Cerrar sesión
 */
export const userLogout = () => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("admin/logout");
      if (!response?.data?.message) {
        throw new Error("Error al cerrar sesión.");
      }
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
    localStorage.removeItem("token");
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: LOGOUT_USER_SUCCESS });
  };
};

/**
 * Recuperar contraseña
 */
export const userRecovery = ({ email }: { email: string }) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("admin/auth/recovery", { email });
      if (!response?.data?.message) {
        throw new Error("Error al recuperar contraseña.");
      }
      dispatch({ type: FETCH_SUCCESS });
      dispatch(push("/reset"));
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

/**
 * Resetear contraseña
 */
export const userReset = ({
  token,
  email,
  password,
  password_confirmation,
}: {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("admin/auth/reset", {
        token,
        email,
        password,
        password_confirmation,
      });
      if (!response?.data?.message) {
        throw new Error("Error al restablecer contraseña.");
      }
      dispatch({ type: FETCH_SUCCESS });
      dispatch(push("/login"));
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

export const getProfile = () => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.get("admin/profile");
      if (!response?.data?.id) {
        dispatch({
          type: FETCH_ERROR,
          payload: "Error al obtener datos del perfil",
        });
      }
      dispatch({ type: FETCH_SUCCESS });
      // dispatch({ type: USER_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
      throw message;
    }
  };
};
