import { Alert, Spin } from "antd";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { hideMessage } from "../../redux/actions/Common";

type IProps = {
  children: React.ReactNode;
  errorTitle?: string;
  label?: string;
};

const InfoView = (props: IProps) => {
  const { children, errorTitle, label } = props;

  const dispatch = useDispatch();
  const error = useSelector((state: any) => state.commonData.error);
  const loading = useSelector((state: any) => state.commonData.loading);
  const message = useSelector((state: any) => state.commonData.message);

  return (
    <Spin spinning={loading} size="large" tip={label}>
      {error ? (
        <Alert
          message={errorTitle ? errorTitle : "Error"}
          description={
            !Array.isArray(error) ? (
              error
            ) : (
              <div>
                <ul className="gx-list-group">
                  {error.map((item, index) => (
                    <li key={`errorItem${index}`}>{item}</li>
                  ))}
                </ul>
              </div>
            )
          }
          type="error"
          showIcon
          closable
          onClose={() => dispatch(hideMessage())}
        />
      ) : null}
      {message ? <Alert message={message} type="info" /> : null}
      {children}
    </Spin>
  );
};

export default InfoView;
