import { Alert } from "antd";
import React from "react";

type IProps = {
  errorTitle?: string;
  error?: string | string[];
};

const AlertError = (props: IProps) => {
  const { errorTitle, error } = props;

  return error ? (
    <Alert
      message={errorTitle ? errorTitle : "Error"}
      description={
        !Array.isArray(error) ? (
          error
        ) : (
          <div>
            <ul className="gx-list-group">
              {error.map((item, index) => (
                <li key={`errorItem${index}`}>{item}</li>
              ))}
            </ul>
          </div>
        )
      }
      type="error"
      showIcon
      closable
    />
  ) : <div></div>;
};

export default AlertError;
