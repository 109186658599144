import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance } from "antd";
import React from "react";

import RouteListItem from "./RouteListItem";

type IProps = {
  form: FormInstance;
  name: string | number | React.ReactText[];
  routes: any[];
  bordered?: boolean;
};

const RouteInputs = (props: IProps) => {
  const { form, name, routes, bordered = false } = props;

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field: any, index: number) => (
            <RouteListItem
              form={form}
              field={field}
              fieldsCount={fields.length}
              remove={remove}
              name={name}
              routes={routes}
              bordered={bordered}
              key={`routes${index}`}
            />
          ))}
          <Button
            type="dashed"
            onClick={() => add()}
            icon={<PlusOutlined />}
            block
          >
            Agregar otra ruta
          </Button>
        </>
      )}
    </Form.List>
  );
};

export default RouteInputs;
