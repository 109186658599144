import {
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  NAV_STYLE,
} from "../../constants/ActionTypes";

export function toggleCollapsedSideNav(navCollapsed: boolean) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width: number) {
  return { type: WINDOW_WIDTH, width };
}

export function onNavStyleChange(navStyle: string | null) {
  return { type: NAV_STYLE, navStyle };
}
