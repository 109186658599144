import React, { Fragment } from "react";

const nl2br = (text: string) => {
  return text
    ? text.split("\n").map((item: string, index: number) => {
        return (
          <Fragment key={`fragment${index}`}>
            {item}
            <br />
          </Fragment>
        );
      })
    : ``;
};

export default nl2br;
