import "assets/vendors/style";
import "styles/main.less";

import { ConfigProvider } from "antd";
import antdSA from "antd/lib/locale-provider/es_ES";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import configureStore, { history } from "./redux/store";
import Root from "./routes/Root";

// provide initial state if any
const { store } = configureStore();

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConfigProvider locale={antdSA}>
        <Switch>
          <Route path="/" component={Root} />
        </Switch>
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
