import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { LOGOUT_USER_SUCCESS } from "../constants/ActionTypes";
import configureStore from "../redux/store";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const { store } = configureStore();

API.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return request;
    }
    request.headers = {
      ...{ Authorization: `Bearer ${JSON.parse(token)}` },
      ...request.headers,
    };
    return request;
  },
  (error) => Promise.reject(error)
);

// Función que se ejecuta cuando se recibe un status 401 (Usuario no autorizado, token no válido, etc.)
const refreshAuthLogic = async (failedRequest: any) => {
  localStorage.removeItem("token");
  store.dispatch({ type: LOGOUT_USER_SUCCESS });
  return (window.location.href = "/login");
};
createAuthRefreshInterceptor(API, refreshAuthLogic, { statusCodes: [401] });
