import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";

import { userLogin } from "../../redux/actions/Auth";
import { InfoView } from "../../components";

type IProps = {} & RouteComponentProps;

const Login = (props: IProps) => {
  const { history } = props;

  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);

  const onFinish = (values: any) => {
    dispatch(userLogin(values));
  };

  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
  }, [token, history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/login.png")} alt="YOLO.mx" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <InfoView
              label="Iniciando sesión"
              errorTitle="Error al iniciar sesión"
            >
              <Form
                initialValues={{}}
                name="loginForm"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                className="gx-login-form gx-form-row0"
              >
                <Typography.Title level={4}>Inicia sesión</Typography.Title>
                <Form.Item
                  name="user"
                  rules={[
                    { required: true, message: "El usuario es obligatorio" },
                  ]}
                  hasFeedback
                  initialValue={null}
                >
                  <Input placeholder="Usuario" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor introduce tu contraseña",
                    },
                  ]}
                  hasFeedback
                >
                  <Input type="password" placeholder="Contraseña" />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-around" align="middle" gutter={[0, 0]}>
                    <Col flex="auto">
                      <Link to="/recovery" className="gx-text-grey">
                        ¿Olvidaste la contraseña?
                      </Link>
                    </Col>
                    <Col flex="100px">
                      <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        className="gx-mb-0"
                        htmlType="submit"
                      >
                        Iniciar sesión
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </InfoView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
