import { Form, FormInstance, Input } from "antd";
import React, { useEffect } from "react";

import { Moment } from "../../util";

type IProps = {
  form: FormInstance;
  name: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  min?: string;
  max?: string;
  bordered?: boolean;
};

const DateSelect = (props: IProps) => {
  const {
    form,
    name,
    label,
    required = false,
    requiredMessage = "Fecha obligatoria",
    min,
    max,
    bordered = true,
  } = props;

  useEffect(() => {
    const values = form.getFieldsValue();
    const date = form.getFieldValue(name);
    form.setFieldsValue({
      ...values,
      [name]: date ? Moment(date).format("YYYY-MM-DD") : null,
    });
  }, [form, name]);

  const onChange = (e: any) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [name]: e.target.value });
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: requiredMessage,
        },
      ]}
      hasFeedback
    >
      <Input
        type="date"
        min={min}
        max={max}
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        bordered={bordered}
        className={bordered ? "gx-w-100" : "gx-input-underlined gx-w-100"}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default DateSelect;
