import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import React, { useEffect, useState } from "react";

type IProps = {
  name: string;
  label: string;
  currentUrl?: string;
  buttonUpload?: string;
  buttonChange?: string;
  required?: boolean;
  requiredMessage?: string;
  onFinish: (file: any) => void;
};

const UploadPicture = (props: IProps) => {
  const {
    name,
    label,
    currentUrl,
    buttonUpload = "Seleccionar foto",
    buttonChange = "Cambiar foto",
    required,
    requiredMessage = "Foto obligatoria",
    onFinish,
  } = props;

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (currentUrl) {
      setFileList([
        {
          uid: "-1",
          name: label,
          status: "done",
          url: currentUrl,
        },
      ]);
    }
  }, [currentUrl, label]);

  const handleChange = (info: any) => {
    let fileList = [...info.fileList];
    // 1. Mostramos solo el último archivo subido
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      valuePropName="file"
      rules={[
        {
          required: required && !fileList.length,
          message: requiredMessage,
        },
      ]}
      hasFeedback
    >
      <Upload
        // accept="image/*"
        beforeUpload={(file) => {
          onFinish(file);
          return false;
        }}
        listType="picture"
        fileList={fileList}
        onChange={handleChange}
        multiple={false}
      >
        <Button block={true} icon={<UploadOutlined />}>
          {currentUrl ? buttonChange : buttonUpload}
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadPicture;
