import { Avatar, Popover } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { userLogout } from "../../redux/actions/Auth";

const UserInfo = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={
        <ul className="gx-user-popover" onClick={handleVisibleChange}>
          <li>
            <Link to="/profile/me" className="gx-text-primary">
              Mi perfil
            </Link>
          </li>
          <li className="ant-divider gx-mt-2 gx-mb-1 gx-height-0"></li>
          <li onClick={() => dispatch(userLogout())}>
            <span className="gx-text-grey">Cerrar sesión</span>
          </li>
        </ul>
      }
      trigger="click"
    >
      <Avatar
        src={require("assets/images/avatar.svg")}
        className="gx-avatar gx-pointer"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;
