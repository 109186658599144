import { CheckOutlined } from "@ant-design/icons";
import URLSearchParams from "@ungap/url-search-params";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";

import { userReset } from "../../redux/actions/Auth";
import { InfoView } from "../../components";

type IProps = {} & RouteComponentProps;

const Reset = (props: IProps) => {
  const { history } = props;
  const { Title, Paragraph } = Typography;
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);
  const params = new URLSearchParams(location.search);
  const userEmail = params.get("email");
  const resetToken = params.get("token");

  const onFinish = (values: any) => {
    dispatch(userReset(values));
  };

  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
  }, [token, history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/login.png")} alt="YOLO.mx" />
            </div>
          </div>
          <div className="gx-app-login-content" style={{ minHeight: "250px" }}>
            <InfoView label="Procesando" errorTitle="Error al procesar">
              <Form
                initialValues={{}}
                name="resetForm"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                className="gx-login-form gx-form-row0"
              >
                <Title level={4}>Restablece tu contraseña</Title>

                <Paragraph className="gx-text-grey">
                  Introduce el código de verificación que enviamos a tu email y
                  la nueva contraseña.
                </Paragraph>

                <Form.Item
                  name="token"
                  rules={[
                    { required: true, message: "El código es obligatorio" },
                  ]}
                  hasFeedback
                  initialValue={resetToken}
                >
                  <Input placeholder="Código de verificación" />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "El email es obligatorio" },
                    { type: "email", message: "No es un email válido" },
                  ]}
                  hasFeedback
                  initialValue={userEmail}
                >
                  <Input type="email" placeholder="Email de tu cuenta" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Escribe la nueva contraseña" },
                    { min: 6, message: "Debe contener al menos 6 caracteres" },
                  ]}
                  hasFeedback
                >
                  <Input type="password" placeholder="Nueva contraseña" />
                </Form.Item>

                <Form.Item
                  name="password_confirmation"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Confirma la nueva contraseña",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "La contraseña y la confirmación no coinciden"
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Confirma la nueva contraseña"
                  />
                </Form.Item>

                <Form.Item>
                  <Row justify="space-around" align="middle" gutter={[0, 0]}>
                    <Col flex="auto">
                      <Link to="/login" className="gx-text-grey">
                        Iniciar sesión
                      </Link>
                    </Col>
                    <Col flex="100px">
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        icon={<CheckOutlined />}
                        htmlType="submit"
                      >
                        Cambiar contraseña
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </InfoView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
