import { Drawer, Layout } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  toggleCollapsedSideNav,
  updateWindowWidth,
} from "../../../redux/actions/Setting";
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../../constants/ThemeSetting";
import SidebarContent from "./SidebarContent";

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();

  const { navCollapsed, width, navStyle } = useSelector(
    (state: any) => state.settings
  );

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  let drawerStyle = "gx-collapsed-sidebar";

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  } else if (width < TAB_SIZE) {
    drawerStyle = "gx-collapsed-sidebar";
  }
  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} gx-layout-sider-dark`}
      trigger={null}
      collapsed={width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR}
      theme="dark"
      collapsible
    >
      {width < TAB_SIZE ? (
        <Drawer
          className="gx-drawer-sidebar gx-drawer-sidebar-dark"
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          visible={navCollapsed}
        >
          <SidebarContent />
        </Drawer>
      ) : (
        <SidebarContent />
      )}
    </Sider>
  );
};
export default Sidebar;
