import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import createRootReducer from "../reducers";

const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history) // root reducer with router state
);

const enhancer = composeWithDevTools(
  applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    ...middlewares
  )
);

export default function configureStore() {
  const store = createStore(persistedReducer, enhancer);
  let persistor = persistStore(store);

  return { store, persistor };
}
