import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import rules from "./rules";

export const checkPermission = (
  roleId: number,
  action: string,
  data: any = null
) => {
  let roleName = "public";
  switch (roleId) {
    case 1:
      roleName = "admin";
      break;
    case 2:
      roleName = "employee";
      break;
    default:
      roleName = "public";
      break;
  }
  const permissions = rules[roleName];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions?.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions?.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }

  return false;
};

type IProps = {
  perform: string;
  data?: any;
  yes?: () => void;
  no?: () => void;
};

const Can = (props: IProps) => {
  const {
    yes = () => null,
    no = () => <Redirect to="/dashboard" />,
    perform,
    data,
  } = props;

  const { authUser } = useSelector((state: any) => state.auth);

  const roleId = authUser?.employee_type_id;

  return <>{checkPermission(roleId, perform, data) ? yes() : no()}</>;
};

export default Can;
