const rules = {
  visitor: {
    static: [],
  },
  employee: {
    static: [],
    dynamic: {
      // "posts:edit": ({ userId, postOwnerId }) => {
      //   if (!userId || !postOwnerId) return false;
      //   return userId === postOwnerId;
      // },
    },
  },
  admin: {
    static: [
      "employees:list",
      "employees:get",
      "employees:edit",
      "employees:create",
      "employees:delete",
      "employees:toggle-active",
      "employees:change-password",
    ],
  },
};

export default rules;
