import { Skeleton } from "antd";
import { AvatarProps } from "antd/lib/skeleton/Avatar";
import React, { ReactNode, ReactText } from "react";

type IProps = {
  loading: boolean;
  rows?: number;
  rowWidths?: number[] | ReactText[];
  title?: boolean;
  avatar?: AvatarProps;
  children?: ReactNode;
};

const SkeletonLoading = (props: IProps) => {
  const {
    loading,
    rows = 10,
    rowWidths,
    title = false,
    avatar,
    children,
  } = props;

  return loading ? (
    <Skeleton
      loading={true}
      active
      title={title}
      {...{ avatar }}
      paragraph={{ rows: rows, width: rowWidths }}
    />
  ) : (
    <>{children}</>
  );
};

export default SkeletonLoading;
