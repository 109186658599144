import { Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { toggleCollapsedSideNav } from "../../../redux/actions/Setting";
import { UserInfo } from "../../../components";
import { TAB_SIZE } from "../../../constants/ThemeSetting";

const { Header } = Layout;

const Topbar = () => {
  const { width, navCollapsed } = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();

  return (
    <Header>
      {width < TAB_SIZE ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      <Link to="/dashboard" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/w-logo.png")} />
      </Link>

      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-user-nav">
          <UserInfo />
        </li>
      </ul>
    </Header>
  );
};

export default Topbar;
