import React, { Component } from "react";

import { SkeletonLoading } from "../components/SkeletonLoading";

type IProps = {};

type IState = {
  component: any;
};

export default function AsyncComponent(importComponent: any) {
  class AsyncFunc extends Component<IProps, IState> {
    mounted: boolean;

    constructor(props: IProps) {
      super(props);
      this.state = {
        component: null,
      };
      this.mounted = false;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        });
      }
    }

    render() {
      const Component = this.state.component || null;
      return (
        <SkeletonLoading loading={Component === null}>
          {Component}
        </SkeletonLoading>
      );
    }
  }

  return AsyncFunc;
}
